import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Sparkling Clean Services
			</title>
			<meta name={"description"} content={"Ihr Zuhause, unsere Mission"} />
			<meta property={"og:title"} content={"Home | Sparkling Clean Services"} />
			<meta property={"og:description"} content={"Ihr Zuhause, unsere Mission"} />
			<meta property={"og:image"} content={"https://novatid.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://novatid.com/img/1999164.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://novatid.com/img/1999164.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://novatid.com/img/1999164.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://novatid.com/img/1999164.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://novatid.com/img/1999164.png"} />
			<meta name={"msapplication-TileImage"} content={"https://novatid.com/img/1999164.png"} />
			<meta name={"msapplication-TileColor"} content={"https://novatid.com/img/1999164.png"} />
		</Helmet>
		<Components.ToluqyeHeader />
		<Section
			padding="140px 0 140px 0"
			background="#FFFFFF url(https://novatid.com/img/1.jpg) 100% 0% /cover no-repeat scroll padding-box"
			min-height="70vh"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="80%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Sparkling Clean
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--lead" lg-text-align="center">
				Wir bei Sparkling Clean Services glauben, dass ein sauberes Zuhause ein glückliches Zuhause ist. Unsere Mission ist es, außergewöhnliche Reinigungsdienste anzubieten, die Ihre Räume makellos, ordentlich und einladend hinterlassen. Egal, ob Sie eine einmalige Tiefenreinigung oder regelmäßige Wartung benötigen, unser professionelles Team ist hier, um sicherzustellen, dass Ihr Zuhause vor Sauberkeit und Komfort glänzt.
				</Text>
				<Box display="flex" sm-flex-direction="column" sm-text-align="center">
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-light"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						hover-transition="border 0.2s ease-in-out 0s"
						hover-border-color="--color-orange"
						transition="border 0.2s ease-in-out 0s"
					>
						Kontaktieren Sie uns
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			/>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
						Warum sollten Sie sich für Sparkling Clean Services entscheiden?
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Expertenteam: 
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Unsere Reinigungskräfte sind hochqualifizierte Fachkräfte, die stolz auf ihre Arbeit sind.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Umweltfreundliche Produkte: 
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
							Wir verwenden umweltfreundliche Reinigungsprodukte, die sicher für Ihr Zuhause und die Umwelt sind.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Individuelle Pläne: 
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Maßgeschneiderte Reinigungspläne, die zu Ihrem Zeitplan und Ihren Vorlieben passen.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
								Zuverlässiger Service:  
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Konsequente und zuverlässige Reinigung, wann immer Sie sie brauchen.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			text-align="center"
			padding="80px 0"
			sm-padding="40px 0"
			align-items="center"
			justify-content="center"
			display="flex"
		>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
			Erleben Sie den Unterschied von Sparkling Clean
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 0 0"
				width="80%"
				text-align="center"
				align-self="center"
			>
				Wenn Sie sich für Sparkling Clean Services entscheiden, entscheiden Sie sich für Spitzenqualität. Überlassen Sie uns die Reinigung, damit Sie jeden Tag ein frisches und strahlendes Zuhause genießen können. Kontaktieren Sie uns noch heute, um zu erfahren, wie unsere Reinigungsexperten Ihren Wohnraum verwandeln können. Denken Sie daran, bei Sparkling Clean Services ist Ihr Zuhause unsere Mission.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						src="https://novatid.com/img/2.jpg"
						width="auto"
						height="504px"
					/>
				</Box>
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image src="https://novatid.com/img/3.jpg" height="504px" />
				</Box>
				<Box
					padding="10px"
					width="320px"
					height="504px"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image src="https://novatid.com/img/4.jpg" height="504px" />
				</Box>
			</Box>
		</Section>
		<Components.ToluqyeFooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});